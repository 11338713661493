@font-face {
  font-family: 'Bold';
  src: local('Poppins'), url(./Assets/Fonts/Poppins-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Nothing';
  src: local('Nothing'), url(./Assets/Fonts/nothing-font-5x7.ttf) format('truetype');
}
@font-face {
  font-family: 'Italic';
  src: local('Poppins'), url(./Assets/Fonts/Poppins-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Regular';
  src: local('Poppins'), url(./Assets/Fonts/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Black';
  src: local('Poppins'), url(./Assets/Fonts/Poppins-Black.ttf) format('truetype');
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: ease ;
}
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}